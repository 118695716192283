<template>
  <section class="m-favorite m-scroll">

    <!-- 头部导航 -->
    <m-header title="提交申请"> </m-header>
    <!-- 商品信息 -->
    <div v-for="(goods,index) in goodsList" :key="index">
      <div class="goods-wrap van-hairline--top" >
        <div class="avatar"><img :src="goods.goods_sku_pic_url" alt="img"></div>
        <div class="info ">
          <div class="title">
            <p class="van-ellipsis">{{goods.goods_name}}</p>
            <span>x{{goods.disabled?goods.disabled:1}}</span>
          </div>
          <span>{{goods.sku_name}}</span>
          <p>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{goods.goods_price}}</p>
        </div>
      </div>
      <!-- <div v-show="module == 2" class="goods-number">
        <span>申请数量</span>
        <van-stepper v-model="number[index]" :max="goods.goods_number" />
      </div> -->
    </div>
    
    <!-- 选择售后类型 -->
    <div v-show="module == 1" class="module-1">

        <div @click="handleType(1)" class="module-cell van-hairline--bottom">
          <div class="left">
            <img src="@/assets/aftersale/return.png" alt="return">
            <span>退货退款</span>
          </div>
          <div class="right">
            <span>已收货，需要退还商品</span>
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div>

        <!-- <div @click="handleType(2)" class="module-cell van-hairline--bottom">
          <div class="left">
            <img src="@/assets/aftersale/icon_bufa@2x.png" alt="supply">
            <span>补发商品</span>
          </div>
          <div class="right">
            <span>已收货，卖家少发商品</span>
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div>

        <div @click="handleType(3)" class="module-cell van-hairline--bottom">
          <div class="left">
            <img src="@/assets/aftersale/exchange.png" alt="exchange">
            <span>换货</span>
          </div>
          <div class="right">
            <span>因尺寸、错发等问题需要换货</span>
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div> -->

        <div @click="handleType(4)" class="module-cell">
          <div class="left">
            <img src="@/assets/aftersale/refund.png" alt="refund">
            <span>仅退款</span>
          </div>
          <div class="right">
            <span>未发货，只退款</span>
            <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
          </div>
        </div>
    </div>
    <div v-show="module == 2" class="module-2">
      <div @click="picker_1 = true" class="reason m-padding van-hairline--bottom">
        <div class="left">申请原因</div>
        <div class="right">
          <span>{{form.reason}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </div>
    
      <div class="content m-padding">
        <textarea v-model="form.describe"  placeholder="申请描述（必填）：请描述申请售后的具体原因"></textarea>
      </div>
      
      <div class="upload-box m-padding">
        <m-uploads :imgs.sync="imgs" file-name="aftersale" :max="3" text="最多三张"></m-uploads>
      </div>
      <div class="tips-box m-padding">
        <div class="tips-cell">
          <van-collapse v-model="activeNames" style="width: 100%;">
              <van-collapse-item name="1">
                <template #title>
                  <div class="priceBox">
                    <div class="priceTitle">
                      退款金额 
                    </div>
                    <div class="priceContent">
                      {{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{(orderPrice - 0).toFixed(2)}}</div> 
                    </div>
                </template>
                 <p style="font-size: 12px;">产品金额：{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_goods_price}},  运费：{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_freight_price}},税费：{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_tax_price}},  钱包金额：{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderPriceCount.total_wallet_price}}</p>
              </van-collapse-item>
          </van-collapse>
        </div>
        <!-- <div class="tips-cell">
          <div class="cell-left">
            <p>退款金额</p>
            <p>*不可修改，最多${{(orderPrice - 0).toFixed(2)}}，含发货邮费</p>
            
          </div>
          <div class="cell-right"></div>
        </div> -->
        <div class="tips-cell">
          <div class="cell-left">
            <p>退款方式</p>
            <p>*原支付返还：指在线支付仅支持原路返还</p>
          </div>
          <div class="cell-right">原支付返还</div>
        </div>
      </div>

      <div class="cell-box m-padding" @click="picker_2 = true" v-show="form.type != 4">
        <span>退货方式</span>
        <div>
          <span>{{form.returnType}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </div>

      <div class="person">
        <div class="cell-box cell-margin m-padding">
          <span>联系人</span>
          <input v-model="contacts" type="text" placeholder="请输入">
        </div>
        <div class="cell-box cell-margin m-padding">
          <span>联系方式</span>
          <input  v-model="contact_number" type="number" placeholder="请输入">
        </div>
      </div>

      <div class="address" v-show="form.type != 4 && form.returnType == '邮寄送回'">
        <p>收货地址</p>
        <p>
          <img src="@/assets/aftersale/icon_address.png" alt="address">
          <span>Eguiner</span>
          <span>768852671</span>
        </p>
        <div>
          <p>Toronto Lyndon F. Mascarenhas 200 St-Clair Avenue West, Suite101 - W, Toronto, Ontario,</p>
          <span>修改</span>
        </div>
      
      </div>

    </div>

     <div class="customer">
        <van-button v-show="module == 2" @click="onSubmit()" block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">提交申请</van-button>
        <p v-show="module == 2" class="btn-tips">*提交申请后售后专员会与您电话沟通，请保持手机畅通</p>
        <van-button v-show="module != 2" block round plain color="#3284FA" @click="$router.push('/zh/customer')">联系客服人员</van-button>
      </div>   
      
      <!-- 选择售后原因 -->
      <van-action-sheet v-model="picker_1" :actions="reasons"    cancel-text="取消" close-on-click-action @cancel="picker_1 = false" @select="handleReason" />
      <!-- 选择退货方式 -->
      <van-action-sheet v-model="picker_2" :actions="returnType" cancel-text="取消" close-on-click-action @cancel="picker_2 = false" @select="handleReturnType" />
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import MUploads  from '@/components/zh/m-uploads.vue'
import { aftersale } from '@/api/zh/cart.js'
export default {
  name:'AfterSaleSubmit',
  components:{ MHeader, MUploads },
  data(){
    return {
      activeNames: [],
      goodsList: [],
      orderPrice: 0,
      goodsPrice: 0,
      module: 1,
      form: {
        type: 0,
        reason: '请选择申请原因',
        describe: '',
        returnType: '自行送回',
      },
      picker_1:false,
      reasons:[
        {name:'发错或漏发商品'},
        {name:'七天无理由'},
        {name:'质量问题'},
        {name:'仅退款'},
        {name:'其他'}
      ],
      picker_2:false,
      returnType:[ {name:'自行送回'},{name:'邮寄送回'}],
      number: [],
      imgs: [],
      contacts: '',
      contact_number: '',
      isNotBreak: false,
      orderPriceCount: '',
      orderDetail: {order_logistics:''}
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.module == 2 && !this.isNotBreak){
      this.module = 1
      next(false)
    }else{
      next()
    }
  },
  methods:{
    // 选择售后类型
    handleType(index){
      this.form.type = index
      this.module    = 2
    },
    // 选择售后原因
    handleReason(it){
      this.form.reason = it.name
    },
    // 选择退货方式
    handleReturnType(it){
      this.form.returnType = it.name
    },
    // 上传凭证
    uploadImg(file) {
      console.log(file)
    },
    // 点击提交
    onSubmit(){
      let params = {
        order_id: this.$route.query.order,
        mode:this.form.type,
        reason:this.form.reason,
        describe: this.form.describe,
      }

      console.log(this.form)
      let object = {  }
      JSON.parse(this.$route.query.goods).forEach(e=>{
        object[e.split(":")[0]] = e.split(":")[1]
      })
      params.after_sale_goods_info = object
      // params.number = this.number
      
      if(this.form.reason == '请选择申请原因') {
        this.$notify({ type: 'warning', message: '请选择申请原因' });
        return false
      }
      if(this.form.describe == '') {
        this.$notify({ type: 'warning', message: '请完善申请描述' });
        return false
      }
      if(this.form.returnType == '自行送回') {
        params.return_mode = 1
        
      }else {
        params.return_mode = 2
      }

      // if(this.imgs.length <= 0) {
      //   this.$notify({ type: 'warning', message: '请至少添加一张描述图片' });
      //   return false
      // }

      // if(this.contacts == '') {
      //   this.$notify({ type: 'warning', message: '请输入联系人' });
      //   return false
      // }else{
        params.contacts = this.contacts
      // }
      // if(this.contact_number == '') {
      //   this.$notify({ type: 'warning', message: '请输入联系方式' });
      //   return false
      // }else {
        params.contact_number = this.contact_number
      // }

      let pic = []
      if(this.imgs.length != 0){
        this.imgs.forEach(e => {
          pic.push(e.pic_id)
        })
        params.describe_pic = pic.join(',')
      }
      aftersale(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.isNotBreak = true
          this.$router.push( {path: '/zh/order/4?back=account'})
        }
      })
    }
  },
  computed: {
    previewSize: {
      set(){
        return '82px'
      },
      get(){
        let clientWidth = document.body.clientWidth
        return `${82/375*clientWidth}px`
      }
    },
    uploadText(){
      return `上传凭证${this.imgs.length}/3`
    }
  },

  created() {
    const order = JSON.parse(window.sessionStorage.getItem('aftersale'));
    this.orderDetail = JSON.parse(window.sessionStorage.getItem('aftersale'))
    this.orderPriceCount = JSON.parse(window.sessionStorage.getItem('aftersalePrice'))
    this.orderPrice = this.orderPriceCount.refund_amount;
    this.goodsPrice = order.goods_amount - 0
      order.order_goods.forEach(e => {
        JSON.parse(this.$route.query.goods).forEach(el=>{
          if(e.rec_id == el.split(":")[0]) {
            this.goodsList.push(e)
          }
        })
      })
  }
}
</script>

<style lang="less" scoped>
@import './submit.less';
/deep/ .van-hairline--top-bottom::after,.van-hairline-unset--top-bottom::after {
  border: none;

}
/deep/ .van-collapse-item {
    .van-cell {
      padding-left: 0;
      padding-right: 0;
      .priceBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .priceContent {
          color: red;
        }
      }
    }
  }
</style>